@import "global";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.wrapper { 
    background: #fff; 
    margin: 0 auto; 
    max-width: 1240px; 
    padding: 20px 10px;
    position: relative;
    border-radius: 2px;
    box-shadow: 0 4px 14px 0 rgba(188, 188, 188, 0.5);
    @include media-breakpoint-up(xl) {
        margin: 20px auto;
    }
}

#header { 
    color: white; 
    text-align: center; 
    padding: 30px 20px; 
    margin: 0 10px 20px;
    background-image: url('../img/content/header.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    @include media-breakpoint-up(md) {
        height: 540px; 
    }
    .avatar {
        width: 50px;
        height: 50px;
        @include media-breakpoint-up(md) {
            width: 80px;
            height: 80px;
        }
    }
    h1 { 
        color: white; 
        margin: 20px 0; 
        font-size: 1.5rem;
        font-weight: 700;
        @include media-breakpoint-up(md) {
            font-size: 2.5rem;
        }
    }
    p { 
        margin: 0 auto 40px;
        @include media-breakpoint-up(md) {
            max-width: 54%;
        }
    }
}

#footer {
    color: white;
    padding: 40px;
    margin: 0 10px;
    display: block;
    text-align: center;
    background-color: #34495e;
}

#header,
#footer {
    a {
        border: none;
        color: white;
        text-shadow: none;
        transition: 0.25s;
        padding: 10px 15px;
        display: inline-block;
        text-decoration: none;
        background-color: #f1c40f;
        @include hover-focus-active {
            transition: all 0.25s;
            background-color: #f4d03f;
            color: rgba(255, 255, 255, 0.75);
        }
        &.disabled, 
        &[disabled] { 
            background-color: #95a5a6;
            color: rgba(255, 255, 255, 1);
        }        
    }
}

.portfolio {
    &-item {
        margin-bottom: 20px;
        > a {
            display: block;
            position: relative;
            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
                position: absolute;
                background-color: rgba(255,255,255,0);
                transition: background-color .5s ease;
            }
            &:hover:before {
                background-color: rgba(255,255,255,.5);
            }
        }
        &.expanded {
            > a {
                &:before {
                    display: none;
                }
            }
        }
        &-details {
            height: 0;
            opacity: 0;
            left: 20px;
            right: 20px;
            overflow: hidden;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: #f7f7f7;
            transition: height .2s ease;
            @include media-breakpoint-up(md) {
                position: absolute;
                padding: 20px 40px 0;
                width: calc(100% - 40px);
            }
        }
        &-title {
            font-size: 1.5rem;
            @include media-breakpoint-up(lg) {
                font-size: 1.75rem;
            }
        }
        &-text {
            font-size: .8125rem;
            @include media-breakpoint-up(lg) {
                font-size: .875rem;
            }
        }
        &-images{
            position: relative;
        }
        &.hovered {
            z-index: 0;
            > a {
                &:before {
                    background-color: rgba(247,247,247,.75);
                }
                &:hover:before {
                    background-color: rgba(255,255,255, .35);
                }
            }
        }
        &.expanded {
            z-index: 1;
            position: static;
            @include media-breakpoint-up(md) {
                margin-bottom: 360px;
            }
            @include media-breakpoint-up(lg) {
                margin-bottom: 420px;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 480px;
            }
            .portfolio-item-details {
                opacity: 1;
                height: 500px;
                padding: 20px 20px 0;
                border-top: 20px solid white;
                transition: height 0s, opacity 0s;
                @include media-breakpoint-up(md) {
                    height: 340px;
                    padding: 0 20px;
                    width: calc(100% - 40px);
                }
                @include media-breakpoint-up(lg) {
                    height: 400px;
                    padding: 20px 40px 0;
                }
                @include media-breakpoint-up(xl) {
                    height: 460px;
                }
            }
        }
        .pane {
            align-self: flex-end;
            max-width: calc(100% - 40px);           
        }
        .pane-alt {
            bottom: 0;
            left: 20px;
            width: 55%;            
            position: absolute;
            @include media-breakpoint-up(lg) {
                left: 0;
            }
        }
        &.alt {
            .pane {
                align-self: flex-start;
            }
            .pane-alt {
                left: auto;
                right: 20px;
                @include media-breakpoint-up(lg) {
                    right: 0;
                }
            }
        }
        .screen {
            border-radius: 2px;
            box-shadow: 0 4px 14px 0 rgba(188, 188, 188, 0.5);
        }
        .fab {
            font-size: 22px;
        }
        .icon {
            vertical-align: top;
            filter: grayscale(1);
        }        
    }
}