// Body
$body-bg: #f7f7f7;

// Colours
$dark-blue: #1f145d;
$dark-purple: #652D86;
$purple: #9C5FB5;
$dull-purple: #8a6ba3;
$yellow: #f1c40f;
$off-white: #f8f8f8;
$nearly-black: #212529;
$turquoise: #00c1d4;
$muddy-green: #878787;

$theme-colors: (
    'dark-blue': $dark-blue,
    'dark-purple': $dark-purple,
    'purple': $purple,
    'dull-purple': $dull-purple,
    'yellow': $yellow,
    'off-white': $off-white,
    'nearly-black': $nearly-black,
    'turquoise': $turquoise,
    'muddy-green': $muddy-green
);

$colors: (
    'dark-blue': $dark-blue,
    'dark-purple': $dark-purple,
    'purple': $purple,
    'dull-purple': $dull-purple,
    'yellow': $yellow,
    'off-white': $off-white,
    'nearly-black': $nearly-black,
    'turquoise': $turquoise,
    'muddy-green': $muddy-green
);


// Grid Breakpoints
$grid-breakpoints: (
   xxs: 0,
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
   xxl: 1440px
);

// Grid Containers
$container-max-widths: (
    xs: 320px,
    sm: 540px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
   xxl: 1440px
);

$grid-gutter-width: 20px;

// Links
$link-color: $yellow;

// Body
$body-color: $nearly-black;

// Typography

// Font
$font-family-base: 'Arial', sans-serif;
$font-color-base: $nearly-black;
$font-weight-base: 400;

// Heading
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: 800;
$headings-color: $nearly-black;
